import React from 'react';
import Layout from 'layout';
// eslint-disable-next-lin../components/ui/Buttonfault
import Button from 'components/ui/button';
import Link from 'components/ui/link';
import Cookies from 'js-cookie';

const Optout = () => {
  const isOptOut = Cookies.get('__ls_optout') || false;
  return (
    <Layout
      metaTitle="Opt-out"
      canonical="/opt-out/"
      metaDescription="LiveSession respects your privacy. You can opt-out if you wish to stay anonymous while you interact with the website."
    >
      <section className="mt-1 mb-5">
        <div className="container mt-3 mb-5">
          <div
            className="col-md-8 col-sm-12 col-ll-6 col-xl-6 m-auto p-5 text-center"
            style={{ border: '1px solid #ebebef' }}
          >
            <h1
              className="text-center"
              style={{
                fontWeight: 800,
                fontSize: '1.65rem',
                marginBottom: '14px',
              }}
            >
              {isOptOut ? 'LiveSession is off.' : 'Opt out from livesession.io'}
            </h1>
            {!isOptOut && (
              <h2 style={{ fontSize: '1rem' }}>
                Your activity won&apos;t be analyzed on LiveSession website.
              </h2>
            )}
            <p>
              <Link href="/help/opt-out-cookie/" className="mt-2">
                How to provide opt-out for my website?
              </Link>
            </p>
            {!isOptOut && (
              <Button
                id="opt-out-btn"
                className="button big fill blue mt-4"
                href="/?__ls_optout=1"
                center
              >
                Opt out LiveSession
              </Button>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Optout;
